import React from 'react'
import SingleArticle from '../templates/SingleArticle'

import {graphql} from 'gatsby'

const Article = ({data}) => {

  const article = data.contentfulArticle;
  
  return (
    <SingleArticle article = {article}/>
  )
}

export const query = graphql`

    query getSingleArticle($title: String) {
        contentfulArticle(title: {eq: $title}) {
            title
            description {
                description
            }
            content {
                raw
                # references {
                #     ... on ContentfulAsset {
                #         gatsbyImageData(width: 1000, placeholder: TRACED_SVG)
                #         description
                #         contentful_id
                #         __typename
                #         title
                #         file{
                #             url
                #         }
                #     }
                # }
            }
            date(formatString: "DD MMMM YYYY")
            image {
                gatsbyImageData(layout: CONSTRAINED, placeholder: DOMINANT_COLOR)
                file {
                    url
                }
            }
            author
            category {
                id
                name
            }
        }
    }
`

export default Article