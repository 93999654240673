import React from "react";
import PropTypes from "prop-types";

import { INLINES, BLOCKS, MARKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text.js";
import { GatsbyImage, getImage } from "gatsby-plugin-image";


const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <b className="font-bold">{text}</b>,
    [MARKS.ITALIC]: (text) => <i className="font-italic">{text}</i>,
    [MARKS.UNDERLINE]: (text) => <u className="underline">{text}</u>,
    [MARKS.CODE]: (text) => (
      <code className="font-mono px-1 py-2 mx-1 bg-gray-100 rounded text-sm">
        {text}
      </code>
    ),
  },
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => (
      <a
        href={node.data.uri}
        target="_blank"
        rel="noreferrer"
        className="underline"
      >
        {children}
      </a>
    ),
    [BLOCKS.HEADING_1]: (node, children) => (
      <h2 className="text-2xl py-6">{children}</h2>
    ),
    [BLOCKS.HEADING_2]: (node, children) => {
      return <h2 className="text-2xl py-6 text-gray-700">{children}</h2>;
    },
    [BLOCKS.HEADING_3]: (node, children) => (
      <h3 className="text-xl py-6 text-gray-700">{children}</h3>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <h4 className="text-xl py-4 text-gray-700">{children}</h4>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
      <h5 className="text-xl py-4 text-gray-700">{children}</h5>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
      <h6 className="text-xl py-4 text-gray-700">{children}</h6>
    ),

    [BLOCKS.OL_LIST]: (node, children) => (
      <ol className="list-decimal pl-4">{children}</ol>
    ),
    [BLOCKS.UL_LIST]: (node, children) => (
      <ul className="list-disc py-2 pl-4">{children}</ul>
    ),

    [BLOCKS.LIST_ITEM]: (node, children) => (
      <li className="mb-2">{children}</li>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => {
      if (node.content[0].value === "") {
        return <br />;
      } else {
        return <p className="pb-4">{children}</p>;
      }
    },
    [BLOCKS.QUOTE]: (children) => (
      <blockquote className="bx--type-quotation-01">
        <>"{children.content[0].content[0].value}"</>
      </blockquote>
    ),
    [BLOCKS.HR]: () => <hr className="mb-6" />,

    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { gatsbyImageData, description } = node.data.target;

      return (
        <GatsbyImage
          image={getImage(gatsbyImageData)}
          alt={description}
          className="object-cover mx-auto "
        />
      );
    },
  },
};

const ContentfulRichTech = ({ richText }) => {
  return <div>{renderRichText(richText, options)}</div>;
};

ContentfulRichTech.propTypes = {
  /** Contentful rich text */
  richText: PropTypes.any.isRequired,
};

export default ContentfulRichTech;
