import React from "react"

import { Fragment, useState } from "react"
import {
  Dialog,
  Disclosure,
  Menu,
  Popover,
  Transition,
} from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/solid"



const SocialLinks = ({ url, title }) => {
  const fbLink = `https://www.facebook.com/sharer.php?u=${url}`
  const twitterLink = `https://twitter.com/share?url=${url}&text=${title}`
  const liLink = `https://www.linkedin.com/shareArticle?url=${url}&title=${title}`
  const emailLink = `mailto:?subject=I wanted you to see this article&body=Check out this article ${url}`

  const defaultFilters = [
    {
      id: "share",
      name: "SHARE",
      options: [
        { value: fbLink, label: "Facebook", icon:          <svg
        fill="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        className="w-5 h-5"
        viewBox="0 0 24 24"
      >
        <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
      </svg>},
        { value: twitterLink, label: "Twitter", icon:         <svg
        fill="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        className="w-5 h-5"
        viewBox="0 0 24 24"
      >
        <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
      </svg> },
      //   { value: liLink, label: "LinkedIn", icon:         <svg
      //   fill="currentColor"
      //   stroke="currentColor"
      //   strokeLinecap="round"
      //   strokeLinejoin="round"
      //   strokeWidth="0"
      //   className="w-5 h-5"
      //   viewBox="0 0 24 24"
      // >
      //   <path
      //     stroke="none"
      //     d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"
      //   ></path>
      //   <circle cx="4" cy="4" r="2" stroke="none"></circle>
      // </svg> },
        { value: emailLink, label: "Email", icon:         <svg
        fill="currentColor"
        stroke="currentColor"
        className="w-5 h-5"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path d="M464 64C490.5 64 512 85.49 512 112C512 127.1 504.9 141.3 492.8 150.4L275.2 313.6C263.8 322.1 248.2 322.1 236.8 313.6L19.2 150.4C7.113 141.3 0 127.1 0 112C0 85.49 21.49 64 48 64H464zM217.6 339.2C240.4 356.3 271.6 356.3 294.4 339.2L512 176V384C512 419.3 483.3 448 448 448H64C28.65 448 0 419.3 0 384V176L217.6 339.2z" />
      </svg> },
      ],
    },
  ]

  return (
    <Popover.Group className="sm:flex sm:items-baseline sm:space-x-8">
      {defaultFilters.map((section, sectionIdx) => (
        <Popover
          as="div"
          key={section.name}
          id={`desktop-menu-${sectionIdx}`}
          className="relative z-10 inline-block text-left"
        >
          <div>
            <Popover.Button className="group inline-flex items-center justify-center font-heading text-ec-sea text-lg hover:font-semibold transition ease-in-out">
              <span>{section.name}</span>
              <ChevronDownIcon
                className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-ec-sea"
                aria-hidden="true"
              />
            </Popover.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Popover.Panel className="origin-top-right absolute right-0 mt-2 bg-white rounded-md shadow-2xl p-4 ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="space-y-4">
                {section.options.map((option, optionIdx) => (
                  <a key={option.value} href= {option.value} className="flex items-center group cursor-pointer" target= "blank">
                    <div className="text-ec-sea group-hover:text-ec-earth transition ease-in-out">{option.icon}</div>
                    <div
                      htmlFor={`filter-${section.id}-${optionIdx}`}
                      className="ml-3 pr-6 text-sm font-medium text-gray-900 whitespace-nowrap"
                    >
                      {option.label}
                    </div>
                  </a>
                ))}
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
      ))}
    </Popover.Group>
  )
}

export default SocialLinks
