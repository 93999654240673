import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../content/Layout"
import SEO from "../content/Seo"
import ContentfulRichTech from "../content/ContentfulRichText"
import FeaturedArticles from "../content/FeaturedArticles"
import SocialLinks from "../content/SocialLinks"

const SingleArticle = ({ article }) => {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Article",
    headline: article.title,
    image: article.image.file.url,
    description: article.description.description,
    author: {
      "@type" : "Person",
      name : article.author
    },
    datePublished : article.date
  }
  return (
    <Layout>
      <SEO
        title={article.title}
        description={article.description.description}
        image={article.image.file.url}
        slug={article.title
          .toLowerCase()
          .replaceAll(" ", "")
          .replaceAll("'", "-")
          .replaceAll(":", "-")}
      />
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
      <main className="max-w-screen-2xl px-4 mx-auto pt-40 pb-12 sm:px-6 lg:px-8">
        <div className="flex justify-between py-4 ">
          <Link className="flex items-center gap-2" to="/articles">
            <svg
              width="16"
              height="14"
              viewBox="0 0 16 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3 7L15 1.80385V12.1962L3 7Z"
                fill="#0D3552"
                stroke="#0D3552"
                stroke-width="2"
              />
            </svg>

            <h4 className="font-heading text-ec-sea text-lg hover:font-semibold transition ease-in-out">
              BACK
            </h4>
          </Link>
          <SocialLinks />
        </div>

        <div className="max-w-7xl mx-auto">
          <h1 className="font-heading text-3xl text-ec-earth text-center py-4 ">
            {article.title}
          </h1>
          <div className="font-body text-xl text-ec-sea/60 text-center mb-12">{`${article.author} l ${article.category.name} l ${article.date}`}</div>
          <GatsbyImage
            image={article.image.gatsbyImageData}
            alt={article.title}
            className="w-full px-4 max-h-[600px]"
          />
          <div className="py-12 text-lg text-ec-sea ">
            <ContentfulRichTech richText={article.content} />
          </div>
        </div>
        <section aria-labelledby="related-heading" className="mt-16 sm:mt-24">
          <div className="text-3xl mx-auto text-center pb-24">
            <h2 className="px-4 font-heading text-ec-earth pb-2">Read More</h2>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="334"
              height="12.219"
              viewBox="0 0 334 12.219"
              className="mx-auto"
            >
              <g
                id="Feather_line"
                data-name="Feather line"
                transform="translate(487.997 -950.5) rotate(90)"
              >
                <line
                  id="Line_15"
                  data-name="Line 15"
                  y1="124.003"
                  transform="translate(957.5 153.997)"
                  fill="none"
                  stroke="#a85e36"
                  stroke-width="2"
                />
                <line
                  id="Line_16"
                  data-name="Line 16"
                  y1="124.997"
                  transform="translate(957.5 363)"
                  fill="none"
                  stroke="#a85e36"
                  stroke-width="2"
                />
                <ellipse
                  id="Ellipse_13"
                  data-name="Ellipse 13"
                  cx="5.841"
                  cy="5.841"
                  rx="5.841"
                  ry="5.841"
                  transform="translate(951.038 290.453)"
                  fill="#a85e36"
                />
                <ellipse
                  id="Ellipse_14"
                  data-name="Ellipse 14"
                  cx="5.841"
                  cy="5.841"
                  rx="5.841"
                  ry="5.841"
                  transform="translate(951.038 339.95)"
                  fill="#a85e36"
                />
                <path
                  id="Polygon_9"
                  data-name="Polygon 9"
                  d="M7,0l7,12H0Z"
                  transform="translate(950.5 328.497) rotate(-90)"
                  fill="#a85e36"
                />
              </g>
            </svg>
            <FeaturedArticles />
          </div>

          {/* <div className="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
            {relatedProducts.map((relatedProduct) => (
              <div key={relatedProduct.id} className="group relative">
                <div className="w-full min-h-80 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-80 lg:aspect-none">
                  <img
                    src={relatedProduct.imageSrc}
                    alt={relatedProduct.imageAlt}
                    className="w-full h-full object-center object-cover lg:w-full lg:h-full"
                  />
                </div>
                <div className="mt-4 flex justify-between">
                  <div>
                    <h3 className="text-sm text-gray-700">
                      <a href={relatedProduct.href}>
                        <span aria-hidden="true" className="absolute inset-0" />
                        {relatedProduct.name}
                      </a>
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      {relatedProduct.color}
                    </p>
                  </div>
                  <p className="text-sm font-medium text-gray-900">
                    {relatedProduct.price}
                  </p>
                </div>
              </div>
            ))}
          </div> */}
        </section>
      </main>
    </Layout>
  )
}

export default SingleArticle
