import React from "react"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const query = graphql`
  {
    allContentfulArticle(
      sort: { fields: createdAt, order: DESC }
      filter: { featured: { eq: true } }
      limit: 3
    ) {
      nodes {
        id
        title
        
        category {
          id
          name
        }
        image {
          gatsbyImageData
        }
        description {
          description
        }
      }
    }
  }
`

const FeaturedArticles = () => {
  const data = useStaticQuery(query)
  const articles = data.allContentfulArticle.nodes
  return (
    <div className="container max-w-screen-2xl mx-auto px-4 my-12 sm:px-6 lg:px-8">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
        {articles.map((article) => {
          return (
            <Link
              key={article.id}
              to={`/${article.title
                .toLowerCase()
                .replaceAll("!", "")
                .replace(/[:.'() ]/g, "-")
                .replaceAll("--", "-")
                .replaceAll("--", "-")
                .replaceAll("?-", "-")}`}
              className="p-4 w-full  mb-6 relative hover:bg-gray-100 transition"
            >
              <div>
                <div className="h-96 overflow-hidden hover:opacity-90 transition rounded-tr-[100px] rounded-bl-[100px]">
                  <GatsbyImage
                    alt="content"
                    className="object-cover object-center h-full w-full"
                    image={article.image.gatsbyImageData}
                  />
                </div>
              </div>
              <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1 mt-6">
                {article.date}
              </h2>
              <h2 className="text-3xl font-medium font-heading text-ec-earth mt-5 max-h-20 overflow-hidden">
                {article.title}
              </h2>
              <p className="font-body text-ec-sea text-lg leading-relaxed mt-2 mb-3 h-20 overflow-hidden">
                {`${article.description.description.slice(0, 120)}...`}
              </p>
            </Link>
          )
        })}
      </div>
    </div>
  )
}

export default FeaturedArticles
